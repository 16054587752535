<template>
  <div class="row pt-1" v-if="berechtigungen.m_dozierende.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.lecturers") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div
                :class="kachelCol"
                v-if="berechtigungen.b_bildung_anwesenheit.read"
              >
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'Anwesenheitsliste' }">
                    <div class="tile bg-ocher inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-calendar-check"
                          transform="shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_presence") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <!-- neue Präsenzliste -->
              <!-- <div
                :class="kachelCol"
                v-if="berechtigungen.b_bildung_anwesenheit.read"
              >
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link to="/Dozierende/Praesenzliste">
                    <div class="tile bg-ocher inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-user"
                          trasnform="shrink-4 left-1"
                        />
                      </div>
                      <div class="title">
                        <div>{{ $t("global.presences") }}</div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div> -->
              <div
                :class="kachelCol"
                v-if="!isDozent && berechtigungen.m_dozierende.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'dozentenbuchung-liste' }">
                    <div class="tile bg-ocher inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-user-clock"
                          transform="shrink-4 left-1"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.bookings") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'Dozenteneinsatzliste' }">
                    <div class="tile bg-sand inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-user-clock"
                          transform="shrink-4 left-1"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.deployment") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelCol"
                v-if="berechtigungen.b_bildung_noteneingabe.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'noteneingabe-liste' }">
                    <div class="tile bg-sand inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-star"
                          transform="shrink-2 left-1"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.scoreinput") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "StartseiteDozierende",
  components: {},
  mixins: [],
  props: {
    angemeldeterMitarbeiter: { type: Object },
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {
      isDozent: false,
    };
  },
  created() {},
  mounted() {
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;
  },
  computed: {},
  methods: {},
};
</script>
