<template>
  <div class="row pt-1" v-if="berechtigungen.m_lead.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.leadmanagement") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'lead-liste' }">
                    <div class="tile bg-red inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-regular fa-tachometer-alt"
                        />
                      </div>
                      <div class="title">
                        <span>{{ $t("global.leads") }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div :class="kachelKleinCol">
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'aktivitaet-liste' }">
                    <div class="tile bg-light-red inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-solid fa-alarm-clock"
                          transform="shrink-4"
                        />
                      </div>
                      <div class="title">
                        <span>{{ $t("global.activities") }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.m_kampagne.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'kampagne-liste' }">
                    <div class="tile bg-light-red inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-regular fa-chart-gantt"
                          transform="shrink-4"
                        />
                      </div>
                      <div class="title">
                        <span>{{ $t("global.campaigns") }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.m_kampagne.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'beratungsauftrag-liste' }">
                    <div class="tile bg-light-red inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fas fa-clipboard-list"
                          transform="shrink-4"
                        />
                      </div>
                      <div class="title">
                        <span>Beratungsaufträge</span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartseiteLeadManagement",
  components: {},
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
